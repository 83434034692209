import { createRouter, createWebHistory } from 'vue-router'
import Pocetna from '../views/Pocetna.vue'

const routes = [
  {
    path: '/',
    name: 'Pocetna',
    component: Pocetna
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/usluge',
    name: 'Usluge',
    component: () => import('../views/Usluge.vue')
  },
  {
    path: '/kontakt',
    name: 'Kontakt',
    component: () => import('../views/Kontakt.vue')
  },
  {
    path: '/novosti',
    name: 'Novosti',
    component: () => import('../views/Novosti.vue'),
  },
  {
    path: '/clanak:id',
    name: 'Clanak',
    component: () => import('../views/Clanak.vue'),
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import('../views/Admin.vue')
  }
]

const router = createRouter({
  mode: 'history',
  history: createWebHistory(process.env.BASE_URL),
  // eslint-disable-next-line no-unused-vars
  scrollBehavior(to, from, savedPosition) {
    //console.log(to, to.hash);
    //console.log(from,to);
    if (to.hash!='') {
      window.scrollTo({top: 0, behavior: 'auto'});
      setTimeout(() => {
        const element = document.getElementById(to.hash.replace(/#/, ''))
        if (element && element.scrollIntoView) {
          const y = element.getBoundingClientRect().top + window.pageYOffset -80;
          window.scrollTo({top: y, behavior: 'smooth'});
        }
      }, 300)
  
      //NOTE: This doesn't work
      return {selector: to.hash}
    }
    else if(to.name==from.name){
      //console.log('uso');
      setTimeout(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});
      },300);
      return {selector: to.hash}
    }
    else if (savedPosition) {
      return savedPosition
    }
    console.log("USO2");
    return {top: 0};
  },
  routes
})

router.mode="history";

export default router
