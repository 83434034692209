<template>
  <div id="pocetna">
    <div id=image-holder>
      <div class="kolona">
        <p class="p-usluga leva"><router-link to="/usluge#usluga1"><div>Konsultacije prilikom <br> pokretanja poslovanja</div></router-link> </p>
        <p class="p-usluga leva"><router-link to="/usluge#usluga3"><div>Prelazak sa prostog <br> na dvojno knjigovodstvo</div></router-link> </p>
        <p class="p-usluga leva"><router-link to="/usluge#usluga5"><div>PDV i ostali obračuni poreza</div></router-link> </p>

      </div>

        <div><img id="logo2" src="@/assets/logo3.svg" alt="KONTOHERB logo"></div>

      <div class="kolona">
        <p class="p-usluga desna"><router-link to="/usluge#usluga2"><div>Računovodstvene i <br> knjigovodstvene usluge</div></router-link> </p>
        <p class="p-usluga desna"><router-link to="/usluge#usluga4"><div>Obračun zarada i<br> kadrovska evidencija</div></router-link> </p>
        <p class="p-usluga desna"><router-link to="/usluge#usluga6"><div>Finansijski izveštaji</div></router-link> </p>

      </div>

      <div class="pozadina" id="slika1"></div>
      <div class="bela-pozadina"></div>
    </div>

    <div id="o-nama">
      <div class="naslov"><h1>O nama</h1></div>
      <p>Mi smo knjigovodstvena agencija sa dugogodišnjim iskustvom. Odlikuje nas profesionalnost, posvećenost radu, kvalitetna usluga prilagođena svakom klijentu. Pružamo redovne poslovne informacije prilagođene vašim potrebama.  Imamo računovodstvene programe koji mogu da se prilagode različitom obimu i vrsti poslovanja. Naši klijenti se bave proizvodnjom, trgovinom, ugostiteljskim uslugama, prevozom robe i usluga u zemlji i inostranstvu, advokatskim uslugama, IT uslugama, uslugama iz oblasti zdravstva, marketinga, građevine.
        Naša misija je olakšati vam posao tako da se možete usredsrediti na ono što je zaista važno, na širenje vašeg poslovanja.
      </p>
    </div>
    
    <div id="usluge">
      <div class="naslov"><h1>Usluge</h1></div>
      <div class="card-holder">
        <div class="card" @click="idiNa('/usluge#usluga1')">
          <p class="card-title">Konsultacije prilikom pokretanja poslovanja</p>
          <hr>
          <ul>
            <li>izbor forme i šifre delatnosti</li>
            <li>troškovi budućeg poslovanja</li>
            <li>prava i obaveze</li>
          </ul>
        </div>
        <div class="card"  @click="idiNa('/usluge#usluga2')">
          <p class="card-title">Računovodstvene i knjigovodstvene usluge</p>
          <hr>
          <ul>
            <li>finansijsko knjigovodstvo</li>
            <li>robno-materijalno knjigovodstvo</li>
            <li>devizno poslovanje</li>
          </ul>
        </div>
        <div class="card"  @click="idiNa('/usluge#usluga3')">
          <p class="card-title">Prelazak sa prostog na dvojno knjigovodstvo</p>
          <hr>
          <ul>
            <li>za određene grupe preduzetnika, od 1.1.2019. nadležni organ je uveo obavezu vođenja dvojnog knjigovodstva</li>
            <li>ovom odlukom, nadležni organ podiže nivo transparentnosti i tačnosti u poslovanju preduzetnika</li>
          </ul>
        </div>
        <div class="card"  @click="idiNa('/usluge#usluga4')">
          <p class="card-title">Obračun zarada i kadrovska evidencija</p>
          <hr>
          <ul>
            <li>zarade</li>
            <li>evidencija zaposlenih</li>
            <li>zdravstveno i socijalno osiguranje</li>
          </ul>
        </div>
        <div class="card"  @click="idiNa('/usluge#usluga5')">
          <p class="card-title">PDV i ostali obračuni poreza</p>
          <hr>
          <ul>
            <li>KIR</li>
            <li>KPR</li>
            <li>POPDV</li>
          </ul>
        </div>
        <div class="card"  @click="idiNa('/usluge#usluga6')">
          <p class="card-title">Finansijski izveštaji</p>
          <hr>
          <ul>
            <li>bilansi</li>
            <li>izveštaji uz završni račun za poslovnu godinu</li>
          </ul>
        </div>
      </div>
      <div class="pozadina" id="slika2"></div>
    </div> 

    <div id="kontakt">
      <div class="naslov"><h1>Kontakt</h1></div>
      <div id="kontakt-sadrzaj">
        <iframe id="mapa" v-if="ucitajMapu" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2826.0047280183203!2d20.286606415538696!3d44.90289967909824!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475a5d4b94c346e1%3A0x331610736506b284!2sKontoherb%20Knjigovodstvena%20agencija!5e0!3m2!1ssr!2srs!4v1613597451817!5m2!1ssr!2srs" loading="lazy" width="450" height="500" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
        <div id="mapa" v-else></div>
        <div id="kontakt-kartica">
          <div> <h1><b> Knjigovodstvena agencija <br> Kontoherb </b></h1></div>
          <div> <span class="fa fa-map-marker-alt"></span> <p>Bosanske Krajine 41<br>11273 Beograd </p> </div>
          <div> <span class="fab fa-facebook"></span> <a href="https://sr-rs.facebook.com/agencija.tsfinanc.5" target="_blank"><p>Knjigovodstvena Agencija Kontoherb </p></a> </div>
          <div> <span class="fab fa-instagram"></span> <a href="https://www.instagram.com/knjigovodstvo_kontoherb/" target="_blank"><p>knjigovodstvo_kontoherb</p></a> </div>
          <div> <span class="fa fa-envelope"></span> <a href="mailto:office@kontoherb.com"><p>office@kontoherb.com</p> </a></div>
          <div> <span class="fa fa-phone-alt"></span> <p>069 55 444 28 <br> 011 37 325 02</p> </div>
        </div>
      </div>
      <div><button class="dugme" @click="idiNa('/kontakt#kontakt-forma-p')">Pošaljite nam poruku</button></div>
    </div>

    <div id="utisci">
      <div class="naslov"><h1>Utisci</h1></div>
      <div class="card-holder" style="margin-bottom:0">
        <div class="card2">
          <p>
            Knjigovodstvo nikad nije bilo problem jer od osnivanja svoje preduzetničke agencije sarađujem sa agnecijom Kontoherb. Veoma odgovorni i dostpuni za svaki problem.<br> Sve preporuke!
          </p>
          <div >
            <hr>
            <p class="potpis">
              Miloš Raškovic - DevCypher Team
            </p>
          </div>
        </div>
        <div class="card2">
          <p>
            Sarađujemo dugi niz godina i do sada nismo imali nikakvih zamerki na vaš rad, čak štaviše imamo samo reči hvale. Nadamo se da će tako i ostati</p>
          <div >
            <hr>
            <p class="potpis">
              Pekara "Tuci"
            </p>
          </div>
        </div>
      </div>
      <div class="card-holder" style="margin-top:0">
        <div class="card2">
          <p>
            Želimo da Vam iskažemo veliku zahvalnost na dugogodišnjoj uspešnoj saradnji. Vaša ažurnost i profesionalizam su samo neki od razloga zbog čega biramo vas i rado preporučujemo kolegama i prijateljima. Uvek ljubazni, spremni i dostupni. Vaši saveti, pružanje informacija koje su nam od velikog značaja su dodatni razlozi zbog kojih smo Vaše stalne mušterije.
          </p>
          <div>
            <hr>
            <p class="potpis">
              Branko Marjanović
            </p>
          </div>
        </div>
        <div class="card2">
          <p>
            Na osnovu dosadašnje saradnje možemo da pohvalimo vašu agenciju i gospođu Snežanu koja nam je uvek pomogla oko nedoumica u poslovanju.<br> Nadamo se dugogodišnjem poslovanju na obostrano zadovoljstvo.           </p>
          <div>
            <hr>
            <p class="potpis">
              Beli ogrev
            </p>
          </div>
        </div>
      </div> 

    </div>
    
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Pocetna',
  components: {

  },
  data: function(){
    return(
      {
        ucitajMapu: false
      }
    );
  },
  methods:{
    skroluj(){
      const element = document.getElementById('app');
      element.setAttribute("style", "scroll-behavior: auto;");
      //window.scrollBy(0,-2000);
      window.scrollTo({ top: 0 });
    },
    idiNa(route){
      this.$router.push(route)
    }
  },
  mounted(){
    setTimeout(()=>this.ucitajMapu=true,2000);
  }
}
</script>


<style scoped>
li{
  text-align: left;
}

#pocetna{
  position: relative;
  height: 100%;
  /* margin-top: 80px; */
  width:100%;
}

#image-holder{
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* flex-wrap: wrap; */
  align-items: center;
  position:relative;
  height:100vh;
  padding-bottom: 10vh;
}

@media screen and (max-width: 600px) {
  #image-holder{
    flex-direction: column;
  }
}

.pozadina{
  z-index: -1;
  opacity: 0.6;
  background-repeat: no-repeat;
  position:absolute;
  top:0;
  bottom:0;
  right: 0;
  left: 0;
}

.bela-pozadina{
  background-color: white;
  z-index: -2;
  position:absolute;
  top:0;
  bottom:0;
  right: 0;
  left: 0;
}

#slika1{
  opacity:0.6;
  background-image: url("../assets/bg-01.jpg");
  background-color: rgb(218, 218, 218);
  background-size: cover;
  /* background-position: bottom;  */
}
#slika2{
  z-index: -3;
  position:fixed;
  background-image: url("../assets/bg-04.jpg");
}

#logo2{
  width: 30vw;
  height: 40vh;

  animation-name: flyInTop;
  animation-duration: 2s;

  /* animation-delay: 1s; */
}

.kolona{
  height:100%;
  display:flex;
  flex-direction:column;
  justify-content:center;
}

.p-usluga{
  font-size: 30px;
  margin-top:20px;
  margin-bottom:20px;
  -webkit-text-stroke-width: 0.2px;
  -webkit-text-stroke-color: #3f3f3fff;
  color: #f5871eff;
  font-weight: bold;
  animation-name: flyInBottom;
  animation-duration: 3s;
}

.p-usluga a{
  color: #f5871eff;
}

.p-usluga a:hover{
  /* font-size: 35px; */
  color:#2c3e50;
}

@media screen and (max-width: 900px){
  .p-usluga{
    font-size: 25px;
    margin-top:10px;
    margin-bottom:10px;
  }
}

@media screen and (max-width: 600px) {
  .p-usluga{
    font-size: 20px;
    margin-top:10px;
    margin-bottom:10px;
  }
  #logo2{
    height:20vh;
  }
  .kolona{
    height:30vh;
  }
}

#o-nama{
  background-color: #dcdcdc;
  height:max-content;
  display:flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-content: center; */
  color: #f5871eff;
  position: relative;
}

#o-nama p{
  color:#4d4d4d;
  font-size: 25px;
  text-align: start;
  margin: 70px 10% 70px 10%;
}

.naslov{
  padding:20px;
  background-color: white;
  color: #f5871eff;
  text-transform: uppercase;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  line-height: 80px;
  -webkit-box-shadow: -1px 5px 22px -3px rgba(0,0,0,0.75);
  -moz-box-shadow: -1px 5px 22px -3px rgba(0,0,0,0.75);
  box-shadow: -1px 5px 22px -3px rgba(0,0,0,0.75);
}

#usluge{
  height:max-content;
  display:flex;
  flex-direction: column;
  position:relative;
  min-height: 100vh;
  overflow:hidden;
}

.card-holder{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content:center;
  margin-top:50px;
  margin-bottom:40px;
}

.card{
  margin:20px;
  padding: 20px;
  /* border-color: #f5871eff; */
  border-width: 0.5px;
  -webkit-box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.75);
  width:400px;
  background-color: white;
/*   
  animation-name: flyInBottom2;
  animation-duration: 3s; */
  
  animation-name: zoomout;
  animation-duration: 0.5s;
}


.card2{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin:20px;
  padding: 30px;
  /* border-color: #f5871eff; */
  border-width: 0.5px;
  -webkit-box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.75);
  width:380px;
  background-color: white;
  text-align:left;
  border-radius: 20px;
/*   
  animation-name: flyInBottom2;
  animation-duration: 3s; */
}

.potpis{
  text-align: center;
  color: #212529;
  font-size: 18px;
}

.card:hover{
  cursor: pointer;
  /* transform: scale(1.1); */
  
  animation-name: zoom;
  animation-duration: 0.5s;
  transform: scale(1.05);
}

.card-title{
  color: #f5871eff;
  font-size: 20px;
}

.card hr{
  margin-top: 5px;
}

#kontakt{
  position:relative;
  background-color: #dcdcdc;
}

#kontakt-sadrzaj{
  padding:50px 5% 20px 5%;
  display:flex;
  justify-content:center;
  flex-wrap: wrap;
}

#mapa{
  width:40%;
}

#kontakt-kartica{
  display:flex;
  flex-direction: column;
  justify-content: center;
  width:40%;
  height:500px;
  margin-left:10%;
  /* background-color: white; */
  font-size:25px;
}

#kontakt-kartica div{
  display: flex;
  justify-content: flex-start;
  padding:10px;
  /* line-height: 55px; */
}

#kontakt-kartica div svg{
  margin:10px;
  margin-bottom: 5px;
  font-size:30px;
}

#kontakt-kartica div p{
  text-align:left;
  margin-left:20px;
  margin-bottom: 0;
  margin-top: 6px;
}

#kontakt-kartica a{
  color: #2c3e50;
}



@media screen and (max-width: 1000px) {

  #kontakt-sadrzaj{
    flex-direction: column;
  }

  #kontakt-kartica{
    margin-top:25px;
    margin-left:0;
    font-size:22px;
    width:100%;
    height:100%;
  }

  #mapa{
    width:100%;
  }
}

@media screen and (max-width: 600px) {
  #o-nama p {
    font-size: 15px;
  }
}


@keyframes flyInTop {
   0% {margin-top: -200vh;}
   100% {margin-top: 0px;}
}


@keyframes flyInBottom2 {
   0% {margin-bottom: 40px; opacity:0}
   100% {margin-bottom: 20px; opacity:1}
}

@keyframes flyInBottom {
   0% {margin-bottom: 100vh; opacity:0}
   100% {margin-top:20px;  margin-bottom:20px; opacity:1}
}

@media screen and (max-width: 600px) {
  @keyframes flyInBottom {
    0% {margin-bottom: 100vh; opacity:0}
    100% {margin-top:10px;  margin-bottom:10px; opacity:1}
  }
}


@keyframes flyInLeft {
   0% {margin-right: 100vw;}
   100% {margin-right: 0px;}
}

@keyframes fadeIn {
   0% {opacity: 0;}
   100% {opacity: 1;}
}


@keyframes zoom {
   0% {transform: scale(1.0);}
   100% {transform: scale(1.05);}
}
@keyframes zoomout {
   0% {transform: scale(1.05);}
   100% {transform: scale(1.00);}
}
</style>