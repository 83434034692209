<template>
  <nav >
    <router-link to="/"><img id="logo" src="@/assets/logo.svg" alt="KONTOHERB logo" height="50"></router-link>
    <ul>
      <li> <router-link to="/">Početna</router-link> </li>
      <li> <router-link to="/usluge">Usluge</router-link> </li>
      <li> <a class="cenovnik" @click="otvoriPDF()"> Cenovnik </a></li>
      <li> <router-link to="/novosti">Novosti</router-link> </li>
      <li> <router-link to="/kontakt">Kontakt</router-link> </li>
    </ul>
    <div id="mobile-menu" @click="promeni()"><span class="fa fa-bars"></span></div>
  </nav>
  <div id="overlay" @click="off()">
    <ul>
      <li> <router-link to="/">Početna</router-link> </li>
      <li> <router-link to="/usluge">Usluge</router-link> </li>
      <li> <a @click="otvoriPDF()"> Cenovnik </a></li>
      <li> <router-link to="/novosti">Novosti</router-link> </li>
      <li> <router-link to="/kontakt">Kontakt</router-link> </li>
    </ul>
  </div>
  <router-view @click="off()" />
  <footer>
    <img id="logo-fut" src="@/assets/logo4.svg" alt="KONTOHERB logo" height="50">
  </footer>
  <div id="footer2">
    <p>Web Design - &nbsp; </p><a href="https:\\asprogram.com">asprogram.com</a>
  </div>
</template>

<script>
export default {
  name:"App",
  methods:{
    off: function (){ document.getElementById("overlay").style.display = "none";},
    on: function () { document.getElementById("overlay").style.display = "flex";},
    promeni: function() {
      console.log("POZVAO");
      var overlay=document.getElementById("overlay"); 
      if(overlay.style.display=='none')
        overlay.style.display='flex';
      else
        overlay.style.display='none';       
    },
    otvoriPDF() {
      window.open(process.env.VUE_APP_API_URL+"cenovnik.pdf", '_blank');
    }
  },
  mounted(){
    this.off();
  }
}
</script>

<style>
html{
  scroll-behavior: auto;
}
:root {
    scroll-behavior: auto;
}

a{
  text-decoration: none;
}

input:focus{
  outline: none;
}

.naslov{
  padding:20px;
  background-color: white;
  color: #f5871eff;
  text-transform: uppercase;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  line-height: 80px;
  -webkit-box-shadow: -1px 5px 22px -3px rgba(0,0,0,0.75);
  -moz-box-shadow: -1px 5px 22px -3px rgba(0,0,0,0.75);
  box-shadow: -1px 5px 22px -3px rgba(0,0,0,0.75);
}

#mobile-menu{
  display: none;
}

#image-holder2{
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* flex-wrap: wrap; */
  align-items: center;
  position:relative;
  height:50vh;
  /* padding-bottom: 10vh; */
  background-image: url("./assets/bg-03.jpg");
  opacity:0.8;
  background-position:bottom;
  background-repeat: no-repeat;
  background-size: cover;
  color:#2c3e50;
}


#image-holder2 h1{
  font-size:100px;
  
  -webkit-text-stroke-width: 0.5px;
  -webkit-text-stroke-color: rgb(255, 255, 255);
  color: #f5871eff;
  font-weight: bold;
  text-transform: uppercase;
  
}

@media screen and (max-width: 600px) {
  #image-holder2 h1{
    font-size:60px;
  }
}


.dugme{
  border:none;
  color:white;
  /* color:#f5871eff;  */
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 25px;
  text-transform: uppercase;
  margin:50px;
  width:400px;
  height:50px;
  background-color: #f5871eff;
  -webkit-box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.75);

  
  animation-name: zoomout;
  animation-duration: 0.5s;
}

.dugme:hover{
  /* background-color: #2c3e50; */

  animation-name: zoom;
  animation-duration: 0.5s;
  transform: scale(1.05);
}


@media screen and (max-width: 600px) {
  .dugme{
    width:70vw;
    margin:10vw;
    font-size:20px;
  }
}


input, textarea{
  width: 60%;
  height:50px;
  padding-left: 10px;
  /* margin-top: 30px; */
  margin-bottom: 20px;
  border-radius: 10px;
  border: none;
  border-width:2px;
  border-style:solid;
  border-color: rgb(131, 125, 125);
}


@media screen and (max-width: 600px) {
  input, textarea{
    width: 100%;
  }
}

textarea{
  padding-top: 10px;
  height:100%;
}

input:focus, textarea:focus{
  outline: none;
  border-color: #f5871eff;
}



body{
  overflow-x: hidden; 
  margin:0;
  padding-top:80px;
  /* background-color: #7f7f7f; */
  scroll-behavior: auto;

}

nav ul{
  list-style-type: none;
}

#overlay ul{
  list-style-type: none;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}


#logo{
  margin-bottom:7px;
  /* margin-left: 30px; */
  align-self: flex-start;
}

nav {
  position:fixed;
  top:0;
  left:0;
  right:0;
  z-index: 111;
  height: 80px;
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  width:100%;
  background-color: white;
  line-height: 80px;
  -webkit-box-shadow: -1px 10px 32px -3px rgba(0,0,0,0.75);
  -moz-box-shadow: -1px 10px 32px -3px rgba(0,0,0,0.75);
  box-shadow: -1px 10px 32px -3px rgba(0,0,0,0.75);
}

nav ul{
  display:flex;
  justify-content: space-around;
  margin-right: 30px;
}

nav a {
  text-decoration: none;
  display: inline-block;
  font-weight: bold;
  color: #3f3f3fff;
  margin: 0 30px 0 30px;
}

nav a:hover{
  color: #f5871eff;
  cursor: pointer;
}

#cenovnik:hover{
  color: #f5871eff;
  cursor: pointer;
}

nav a.router-link-exact-active {
  color: #f5871eff;
  /* background-color: rgba(63, 63, 63, 0.712); */
}


footer{
  /* background-color:#2c3e50 ; */
  background-color: rgb(131, 125, 125);
  height:200px;
}

#logo-fut{
  margin-top:50px;
  height:100px;
}

@media screen and (max-width: 800px) {
  #logo-fut{
    margin-top:40px;
    width:70vw;
  }
  footer{
    height:200px;
  }
  nav ul{
    display: none;
    /* visibility: hidden; */
  }
  #mobile-menu{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width:80px;
    margin:10px;
    font-size: 30px;
    color: #f5871eff;
    border-width: 2px;
    border-style: solid;
    border-radius: 10px;
    border-color:rgb(131, 125, 125);
  }
}

#overlay {
  padding-top: 80px;
  position: fixed; /* Sit on top of the page content */
  display: flex; /* Hidden by default */
  flex-direction: column;
  justify-content: flex-start;
  width: 100%; /* Full width (cover the whole page) */
  height: 350px; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(131, 125, 125,0.95); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}


#overlay a {
  font-size:30px;
  text-decoration: none;
  display: inline-block;
  font-weight: bold;
  color: #f5871eff;
  margin: 0 30px 0 30px;
}

#overlay ul{
  padding:0;
  padding-top:30px;
}

#footer2{
  display:flex;
  direction:row;
  justify-content: center;
  align-items: center;
  height:25px;
  background-color: rgb(83, 83, 83);;
  color:rgb(211, 205, 205);
}
#footer2 p{
  margin:0;
  padding: 0;
}

#footer2 a{
  color:rgb(211, 205, 205);
}

</style>
